// This is where you dynamically change information for an identical site with a different domain.
// Don't forget to import any images you may need

import dynamicSiteConfig from '../../../../src/utils/dynamic/dynamicSiteConfig';

/**
 *
 * @param {*} config - config object. Use this to alter any config options you need to
 */

const customConfig = config => {
  const size = window.innerWidth;
  if (size <= 801) {
    setTimeout(() => {
      window.alterConfig('pathChoiceOptions', [
        { label: "No, I don't have a mortgage", value: '0' },
        { label: 'Yes, I have a mortgage', value: '1' },
      ]);
    }, 300);
  }

  /**
   * updates main config from dynamicSites config
   * @param  {object} config
   */
  dynamicSiteConfig(config);
};

export default customConfig;
